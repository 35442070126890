import { get, post, put, deletefn } from '@/http/http'

// 设备类型查询
export const getEquipType = p => get('v1/equiptype/getequiptype', p)

// 设备类型新增
export const addEquipType = p => post('v1/equiptype/addequiptype', p)

// 设备类型编辑
export const editEquipType = p => put('v1/equiptype/updateequiptype', p)

// 设备类型删除
export const deleteEquipType = p => deletefn('v1/equiptype/deleteequiptype', p)

// 设备查询
export const getEquipList = p => get('v1/equipment/getequipment', p)

// 设备添加
export const addEquip = p => post('v1/equipment/addequipment', p)

// 设备修改
export const updateEquip = p => put('v1/equipment/updateequipment', p)

// 设备删除
export const deleteEquip = p => deletefn('v1/equipment/deleteequipment', p)

// 系统设备信息导入
export const importEquipInfo = p => post('v1/equipment/addequosinfo', p)

// 系统设备信息查询
export const getEquipInfo = p => get('v1/equipment/getequosinfo', p)

// 类型数量查询
export const geteqNum = p => get('v1/equipment/getequipmenttype', p)

// 设备总数查询
export const geteqCount = p => get('v1/equipment/getequipcount', p)

// 配置修改
export const updateConfig = p => put('v1/equipment/updateconfig', p)

// 视频流推送/停止
export const videoStream = p => put('v1/equipment/videostream', p)

// 虚拟IP查询
export const getVirtualip = p => get('v1/equipment/getvirtualip', p)

// DTU导入
export const importDTUinfo = p => post('v1/equipment/importdtuinfo', p)

// 设备导出
export const equipExport = p => post('v1/exportfile/export_equipment', p)

// ctp设备类型下拉
export const tcpEquType = p => post('v1/equiptype/getequtype_tcp', p)

// ctp设备型号下拉
export const tcpModelType = p => post('v1/equiptype/getequcode', p)

// ctp设备编号查询
export const tcpCodeQuery = p => post('v1/equipment/equimeirate', p)

// 设备接入方式下拉框
export const insetMethod = p => post('v1/equipment/equaddtype', p)

// 设备接入功能
export const equFunction = p => post('v1/equipment/equfunction', p)

// 查询流地址
export const equCurrent = p => post('v1/equipment/equcurrent', p)

// 断路器配置查询
export const getCircuitEqu = p => post('v1/circuitequ/getcircuitequ', p)

// 断路器配置添加/修改
export const addCircuitEqu = p => post('v1/circuitequ/addcircuitequ', p)

// 断路器开闸/关闸
export const openCircuitEqu = p => post('v1/circuitequ/opencircuitequ', p)

// 设备实时报文
export const getEqumsgData = p => post('v1/equipment/getequmsgdata', p)

// 采集终端关联设备查询
export const getErminAlasso = p => post('v1/infoterminal/geterminalasso', p)

// 采集终端关联设备绑定/修改
export const updateTerminAlasso = p => post('v1/infoterminal/updateterminalasso', p)
